<template>
	<div style="padding: 15px 15px 100px;" v-loading="loading">
		<div class="page-title">
			<div class="line"></div>
			<span v-if="form.Id">编辑线索</span>
			<span v-else>添加线索</span>
		</div>
		<el-card header="基本信息">
			<el-form :model="form" :rules="rules" ref="form" label-width="120px" :inline="true" style="overflow: auto">
				<div style="width: 1166px;">
					<div>
						<el-form-item label="公司名称：" prop="CompanyName">
							<el-input placeholder="公司名称" v-model="form.CompanyName" style="width: 400px;" maxlength="100"></el-input>
						</el-form-item>
						<div style="display: inline-block;margin-left: 80px;">
							<div>
								<el-form-item label="线索来源：" prop="CRMClueFromId">
									<el-select v-model="form.CRMClueFromId" clearable filterable placeholder="请选择来源" style="width:400px">
									    <el-option v-for="item in sourceList" :key="item.Id" :label="item.FromName" :value="item.Id">
											<div style="max-width: 360px;" class="ellipsis">{{item.FromName}}</div>
										</el-option>
									</el-select>
								</el-form-item>
							</div>
							<el-form-item label=" " prop="CRMClueFromRemark" v-if="form.CRMClueFromId === otherId">
								<el-input placeholder="" v-model="form.CRMClueFromRemark" style="width: 400px;" maxlength="100"></el-input>
							</el-form-item>
						</div>
					</div>
					<div>
						<el-form-item label="负责人：" prop="MallEmployeeId">
							<el-select v-model="form.MallEmployeeId" filterable clearable :disabled="form.Id ? true : false" placeholder="请选择负责人" style="width:400px;">
								<el-option v-for="item in employeeList" :key="item.Id" :label="item.label" :value="item.Id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="联系人姓名：" prop="ContactName" style="margin-left: 80px;">
							<el-input placeholder="联系人姓名" v-model="form.ContactName" style="width: 400px;" maxlength="50"></el-input>
						</el-form-item>
					</div>
					<div>
						<el-form-item label="联系人电话：" prop="ContactPhone">
							<el-input placeholder="联系人电话" :value="form.ContactPhone" @input="inputValid" style="width: 400px;" maxlength="11"></el-input>
		<!-- 					<el-input-number v-model="form.ContactPhone" :controls="false" :min="1" :max="99999999999"></el-input-number> -->
						</el-form-item>
						<el-form-item label="联系人职务：" prop="ContactPosition" style="margin-left: 80px;">
							<el-input placeholder="联系人职务" v-model="form.ContactPosition" style="width: 400px;" maxlength="50"></el-input>
						</el-form-item>
					</div>
					<div>
						<el-form-item label="固话：" prop="LandlinePhone">
							<el-input placeholder="区号" v-model="form.LandlinePhoneAreaCode" style="width: 150px;" maxlength="20"></el-input>
							<span style="color: #606266;padding: 0 5px;">-</span>
							<el-input placeholder="" v-model="form.LandlinePhone" style="width: 235px;" maxlength="20"></el-input>
						</el-form-item>
						<el-form-item label="销售备注：" prop="SellRemark" style="margin-left: 80px;">
							<el-input placeholder="" type="textarea" v-model="form.SellRemark" style="width: 400px;" :rows="4" resize="none" maxlength="500"></el-input>
						</el-form-item>
					</div>
					<div>
						<el-form-item label="所在地区：" prop="addressList">
							<el-cascader style="width: 400px;" :options="dataList"  :props="props" clearable placeholder="请选择省/市/区"
								@change="handleAddressChange" v-model="addressList" maxlength="100" />
						</el-form-item>
						<el-form-item label="详细地址：" prop="Address" style="margin-left: 80px;">
							<el-input placeholder="" v-model="form.Address" style="width: 400px;" maxlength="100"></el-input>
						</el-form-item>
					</div>
				</div>
			</el-form>
		</el-card>
		
		<div class="bottom-save-btn">
			<el-button @click="handleBack" style="margin-right: 16px;">取消</el-button>
			<el-button type="primary" @click="submitForm" :loading="saveLoading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index';
	import axios from 'axios';
	
	import {
		employeelistbystate
	} from '@/api/sv1.0.0.js';
	
	import {
		cRMClueSave,
		cRMClueInfo,
		cRMClueFromFilterBoxList
	} from '@/api/cluesManage';
	
	export default {
		data() {
			return {
				saveLoading: false,
				otherId: 0,
				loading: false,
				form: {
					Id: '', //线索来源Id,
					CompanyName: '' , //公司名称,
					CRMClueFromId: '', // 线索来源,
					CRMClueFromRemark: '', //线索来源备注,
					ContactName: '', // 联系人姓名,
					ContactPhone: '', //联系人手机号,
					ContactPosition: '', //联系人职务,
					LandlinePhone: '', //固定电话,
					LandlinePhoneAreaCode: '', //区号
					AreaCode: '',
					Province: '', //省份,
					City: '', //市,
					Area: '', //区,
					Address: '', //详细地址,
					SellRemark: '', //销售备注,
					MallEmployeeId: '', //销售负责人Id
				},
				rules: {
					CompanyName: {
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					},
					ContactPhone: {
						required: true,
						message: '请输入联系人手机号',
						trigger: 'blur'
					}
				},
				
				addressList: [],
				employeeList: [],
				sourceList: [],
				dataList: [],
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
			}
		},
		methods: {
			
			/**
			 * 获取详情信息
			 */
			async getDetails(){
				this.loading = true;
				try{
					const res = await cRMClueInfo({Id: this.form.Id});
					this.initData(res.Result);
				}catch(e){
				}
				this.loading = false;
			},
			initData(obj){
				if (obj.CRMClueFromId === 0){
					obj.CRMClueFromId = '';
				}
				if (obj.MallEmployeeId === 0){
					obj.MallEmployeeId = '';
				}
				this.form = obj;
				let list = [];
				if (obj.Province && obj.City && obj.Area){
					this.dataList.map(item => {
						if (item.name == obj.Province) {
							list[0] = +item.id + '';
							item.child.map(items => {
								if (items.name == obj.City) {
									list[1] = +items.id + '';
									items.child.map(lastitems => {
										if (lastitems.name == obj.Area) {
											list[2] = +lastitems.id + '';
										}
									})
								}
							})
						}
					})
				}
				this.addressList = list;
			},
			
			/**
			 *  获取在职员工列表
			 */
			async getEmployeeList(){
				try{
					const res = await employeelistbystate({EmployeeState: -1});
					this.employeeList = res.Result.map(item=>{
						item.label = item.EmployeeName + '(' + item.Phone + ')';
						return item;
					});
				}catch(e){
					console.log(e)
				}
			},
			
			/**
			 * 获取线索来源列表
			 */
			async getSourceList(){
				try{
					const res = await cRMClueFromFilterBoxList({});
					let other = null;
					let list = [];
					res.Result.map(item=>{
						if (item.FromName === '其他' && item.IsSystemPreset){
							other = item;
						}else{
							list.push(item);
						}
					})
					if (other){
						this.otherId = other.Id
						list.push(other);
					}
					this.sourceList = list;
				}catch(e){
				}
			},
			
			//获取地址数据
			getAddressList() {
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime()).then(res => {
					this.dataList = res.data;
					
					const { id } = this.$route.query;
					if (id){
						this.form.Id = id;
						this.getDetails();
					}
				})
			},
			handleAddressChange() {
				
				if (!this.addressList.length){
					this.form.Province = '';
					this.form.City = '';
					this.form.Area = '';
					return;
				}
				
				let provinceId = this.addressList[0];
				let cityId = this.addressList[1];
				let areadId = this.addressList[2];
				
				this.dataList.map(item => {
					if (item.id == provinceId) {
						this.form.Province = item.name
						item.child.map(items => {
							if (items.id == cityId) {
								this.form.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == areadId) {
										this.form.Area = lastitems.name
									}
								})
							}
						})
					}
				})
			},
			
			inputValid(e){
				let list = e.split('');
				let str = '';
				for (let i=0; i<list.length; i++){
					let key = list[i];
					if (/^[0-9]*$/.test(key)){
						str += key;
					}
				}
				this.form.ContactPhone = str;
			},
			
			/**
			 * 保存
			 */
			submitForm(){
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.sureSave();
					}else{
						this.$message.error('线索信息填写有误，请检查')
					}
				});
			},
			async sureSave(){
				this.saveLoading = true;
				try{
					if (this.form.CRMClueFromId !== this.otherId){
						this.form.CRMClueFromRemark = '';
					}
					const res = await cRMClueSave(this.form);
					this.$message({
						showClose: true,
						message: '保存成功！',
						type: 'success'
					});
					this.handleBack();
				}catch(e){
					//TODO handle the exception
				}
				this.saveLoading = false;
			},
			handleBack(){
				this.$router.back();
			}
		},
		created() {
			this.getEmployeeList();
			this.getSourceList();
			this.getAddressList();
			
			
			
		}
	}
</script>

<style lang="less" scoped>

.bottom-save-btn {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
	
.page-title{
	display: flex;
	align-items: center;
	font-size: 28px;
	line-height: 42px;
	color: #303133;
	margin-bottom: 14px;
	
	.line{
		width: 5px;
		height: 24px;
		background: #409EFF;
		margin-right: 10px;
	}
}
</style>